import React from "react"

import UIFooter from "@ecom/ui/components/Footer"

const defaultCopyright =
  "ПАО «Совкомбанк». Генеральная лицензия Банка России №963. На правах рекламы."

export type FooterProps = {
  shortText?: string
  phones: string[]
  copyright?: React.ReactNode
}

const classes = {
  footer: "content-visibility-auto",
}

export default function Footer({ phones, shortText, copyright = defaultCopyright }: FooterProps) {
  let ShortText

  if (shortText) {
    ShortText = (
      <>
        <span dangerouslySetInnerHTML={{ __html: shortText }} />
        <br />
        <br />
        {copyright}
      </>
    )
  } else {
    ShortText = <>{copyright}</>
  }

  return <UIFooter shortText={ShortText} phones={phones} classes={classes} />
}
